'use client';
import cn from 'classnames';
import React, { memo, ReactNode } from 'react';
import styles from './container.module.scss';

export type TContainerProps = {
  /**
   *
   */
  children: ReactNode;
  /**
   * Пользовательский класс стилей
   */
  className?: string;
  /**
   * Растягивает контейнер
   */
  isFluid?: boolean;

  dataAt?: string;
};

/**
 * @deprecated use grid.Container
 */
export const Container = memo(function Container(
  props: TContainerProps,
): JSX.Element {
  return (
    <div
      data-at={props.dataAt}
      className={cn(styles.container, {
        [String(props.className)]: !!props.className,
        [styles.fluid]: props.isFluid,
      })}
    >
      {props.children}
    </div>
  );
});
