'use client';
import { useState } from 'react';
import Collapsible from 'react-collapsible';
import styles from './accordion.module.scss';
import { AccordionHeader } from './components';

type TAccordionProps = {
  title: string | JSX.Element;
  isOpen?: boolean;
};

export const Accordion = ({
  title,
  children,
  isOpen = false,
}: React.PropsWithChildren<TAccordionProps>): JSX.Element => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(isOpen);

  const handleOpen = (): void => {
    setIsAccordionOpen(true);
  };

  const handleClose = (): void => {
    setIsAccordionOpen(false);
  };

  return (
    <Collapsible
      trigger={<AccordionHeader titleNode={title} isOpen={isAccordionOpen} />}
      open={isAccordionOpen}
      onOpening={handleOpen}
      onClosing={handleClose}
      transitionTime={200}
      className={styles.container}
      triggerClassName={styles.header}
      triggerOpenedClassName={styles.header}
      openedClassName={styles.container}
      contentInnerClassName={styles.content}
    >
      {children}
    </Collapsible>
  );
};
