import { Breakpoint, MediaBreakpoint } from '_shared';
import { useEffect, useState } from 'react';

export const useMediaQuery = (query: string): boolean => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = (): void => setMatches(media.matches);
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [matches, query]);

  return matches;
};

export const useMedia = (): Breakpoint => {
  const isMediumScreen = useMediaQuery(MediaBreakpoint.MEDIUM_SCREEN);
  const isMediumToSmallScreen = useMediaQuery(
    MediaBreakpoint.MEDIUM_SMALL_SCREEN,
  );
  const isLargeScreen = useMediaQuery(MediaBreakpoint.LARGE_SCREEN);
  const isExtraLargeScreen = useMediaQuery(MediaBreakpoint.EXTRA_LARGE_SCREEN);

  if (isExtraLargeScreen) return Breakpoint.EXTRA_LARGE_SCREEN;
  if (isLargeScreen) return Breakpoint.LARGE_SCREEN;
  if (isMediumScreen) return Breakpoint.MEDIUM_SCREEN;
  if (isMediumToSmallScreen) return Breakpoint.MEDIUM_SMALL_SCREEN;

  return Breakpoint.SMALL_SCREEN;
};
