import cn from 'classnames';
import React, { memo, ReactNode } from 'react';
import styles from './typo.module.scss';
import { AllColors } from '../ui-kit.types';

export type TTypoProps = {
  /**
   * Задает размеры шрифта из набора
   * По-умолчанию «m»
   */
  variant?: '4xl' | '3xl' | '2xl' | 'xl' | 'l' | 'm' | 'xm' | 's' | 'xs';
  /**
   * Задает толщину начертания шрифта
   * По-умолчанию «400»
   */
  weight?: '400' | '500' | '600' | '700';
  /**
   * Задает горизонтальное выравнивание текста
   * По-умолчанию «left»
   */
  align?: 'left' | 'center' | 'right';
  /**
   * Дополнительный пользовательский класс
   */
  className?: string;
  /**
   * Цвет текста
   */
  color?: AllColors | 'inherit';
  /**
   * Позволяет прокинуть «сырой» html в виде строки
   * Одновременно может использовать или children, или rawHtml
   */
  rawHtml?: string;
  /**
   *
   */
  lineClamp?: number;
  /**
   * Дочерние компоненты
   * Одновременно может использовать или children, или rawHtml
   */
  children?: ReactNode;
  /**
   * Тип элемента
   */
  type?: 'div' | 'span' | 'p' | 'h1' | 'h2';
  /**
   * Атрибут для тестов
   */
  dataAt?: string;
};

export const Typo = memo(function Typo({
  align = 'left',
  variant = 'm',
  weight = '400',
  color = 'primary',
  type = 'div',
  rawHtml,
  ...props
}: TTypoProps): JSX.Element {
  const defaultProps = {
    'className': cn(styles.typo, {
      [styles[`align-${align}`]]: !!align,
      [styles[`variant-${variant}`]]: !!variant,
      [styles[`weight-${weight}`]]: !!weight,
      [styles[`color-${color}`]]: !!color,
      [styles['line-clamp']]: !!props.lineClamp,
      [String(props.className)]: !!props.className,
    }),
    'style': {
      WebkitLineClamp: props.lineClamp,
    },
    'data-at': props.dataAt,
  };

  if (rawHtml)
    return React.createElement(type, {
      ...defaultProps,
      dangerouslySetInnerHTML: {
        __html: rawHtml,
      },
    });

  return React.createElement(type, defaultProps, props.children);
});
