'use client';
import { useState } from 'react';
import styles from './youtube-video.module.scss';

type TYouTubeVideoProps = {
  link: string;
  title: string;
  posterImg: string;
  buttonImg: string;
  altForPosterImg?: string;
};

export const YouTubeVideo = ({
  link,
  buttonImg,
  posterImg,
  title,
  altForPosterImg = 'poster',
}: TYouTubeVideoProps): JSX.Element => {
  const [isVideoVisible, setIsVideoVisible] = useState(false);

  return (
    <div className={styles['video-container']}>
      <div className={styles.decoration} />
      {isVideoVisible && (
        <iframe
          className={styles.video}
          src={`${link}?autoplay=1`}
          title={title}
          frameBorder="0"
          allow="clipboard-write; encrypted-media; picture-in-picture"
          allowFullScreen
        />
      )}
      {!isVideoVisible && (
        <button
          className={styles['poster-button']}
          onClick={() => setIsVideoVisible(true)}
        >
          <img
            className={styles['video-poster']}
            src={posterImg}
            alt={altForPosterImg}
          />
          <img
            className={styles['video-play-button']}
            src={buttonImg}
            alt="play icon"
          />
        </button>
      )}
    </div>
  );
};
