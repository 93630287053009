export const ArrowIcon = (): JSX.Element => {
  return (
    <svg
      width="12"
      height="6"
      viewBox="0 0 12 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7808 5.62466C11.4358 6.05592 10.8065 6.12584 10.3753 5.78083L5.99996 2.28059L1.62466 5.78083C1.1934 6.12584 0.564103 6.05592 0.219094 5.62466C-0.125915 5.1934 -0.0559939 4.5641 0.375268 4.21909L5.37527 0.219094C5.74049 -0.0730811 6.25944 -0.073081 6.62466 0.219094L11.6247 4.21909C12.0559 4.5641 12.1258 5.1934 11.7808 5.62466Z"
        fill="#333333"
      />
    </svg>
  );
};
