import cn from 'classnames';
import { Typo } from 'components/ui-kit/typo';
import styles from './accordion-header.module.scss';
import { ArrowIcon } from './arrow-icon.component';

type TAccordionHeaderProps = {
  titleNode: string | JSX.Element;
  isOpen: boolean;
};

export const AccordionHeader = ({
  titleNode,
  isOpen,
}: TAccordionHeaderProps): JSX.Element => {
  return (
    <div className={styles.container}>
      {typeof titleNode === 'string' ? (
        <Typo variant="l" weight="600">
          {titleNode}
        </Typo>
      ) : (
        titleNode
      )}
      <div className={cn(styles.arrow, { [styles.arrowOpen]: isOpen })}>
        <ArrowIcon />
      </div>
    </div>
  );
};
