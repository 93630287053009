import { memo } from 'react';
import styles from './spinner.module.scss';

export enum SpinnerSpeed {
  FAST = 600,
  SLOW = 900,
}

type TSpinnerProps = {
  className?: string;
  color?: string;
  thickness?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  gap?: 1 | 2 | 3 | 4 | 5;
  speed?: SpinnerSpeed;
  size?: number | string;
};

export const Spinner = memo(function Spinner({
  color = styles.defaultColor,
  speed = SpinnerSpeed.SLOW,
  gap = 4,
  thickness = 4,
  size = '18px',
  ...props
}: TSpinnerProps) {
  return (
    <svg
      height={size}
      width={size}
      {...props}
      style={{ animationDuration: `${speed}ms` }}
      className={styles.spinner}
      viewBox="0 0 32 32"
    >
      <circle
        cx={16}
        cy={16}
        r={14 - thickness / 2}
        stroke={color}
        fill="none"
        strokeWidth={thickness}
        strokeDasharray={Math.PI * 2 * (11 - gap)}
        strokeLinecap="round"
      />
    </svg>
  );
});
