import styles from './icont-button.module.scss';

type TIconButtonProps = {
  onClick: VoidFunction;
  ariaLabel?: string;
  dataAt?: string;
};

export const IconButton = ({
  children,
  ariaLabel,
  onClick,
  dataAt,
}: React.PropsWithChildren<TIconButtonProps>): JSX.Element => {
  return (
    <button
      data-at={dataAt}
      className={styles.iconButton}
      aria-label={ariaLabel}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
