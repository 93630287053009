'use client';
import cn from 'classnames';
import React, { memo, useCallback, useMemo } from 'react';
import type { Swiper as SwiperCore } from 'swiper';
import {
  Swiper as SwiperReact,
  SwiperSlide as SwiperSliderReact,
} from 'swiper/react';
import styles from './carousel.module.scss';
import { CarouselControl } from './components';
import {
  useCarouselAfterInitialization,
  useCarouselLoopState,
  useNavigationControllersState,
} from './hooks';
import 'swiper/swiper.min.css';

export type TCarouselPropsBreakpoint = Record<
  string,
  { slidesPerView: number; spaceBetween: number }
>;

export type TCarouselProps<T> = {
  /**
   * Данные, которые нужны для отрисовки
   */
  data: Array<T>;
  /**
   * renderProps для слайда
   */
  renderSlide: (data: T) => React.ReactElement;
  /**
   * Достает ключ
   */
  getKey?: (data: T) => string;
  /**
   * количество слайдов
   */
  slidesPerView?: number | 'auto';
  /**
   * дистанция между слайдами
   */
  spaceBetween?: number;
  /**
   * брейкпоинты для количества слайдов и пространства между ними
   */
  breakpoints?: TCarouselPropsBreakpoint;
  /**
   * сдвиг по вертикали для контролов управления
   *
   * Если передавать число, то сдвиг будет в пиксилях,
   * если передать строку, то она попадет свойство top
   */
  offsetYControl?: number | string;
  /**
   * показывать кнопки навигации
   */
  hasNavigationControllers?: boolean;
  /**
   * зацикливать или нет слайды
   */
  loop?: boolean;
};
/**
 * TODO: Выпилисть swiper, сейчас он конфкитует с текущей версией react
 * найти более легковесное решение например react-slidy
 */

const Component = <T extends object>({
  data = [],
  renderSlide,
  getKey,
  slidesPerView = 'auto',
  spaceBetween,
  breakpoints,
  offsetYControl,
  loop = false,
  hasNavigationControllers = true,
}: TCarouselProps<T>): JSX.Element => {
  const countOfElements = data.length;
  const swiperRef = React.useRef<SwiperReact>(null);

  const [carouselLoop, setCarouselLoopState] = useCarouselLoopState(
    loop,
    countOfElements,
  );
  const [
    isPrevNavigationControllerDisabled,
    isNextNavigationControllerDisabled,
    setNavigationControllerState,
    sliderParams,
  ] = useNavigationControllersState(carouselLoop);

  const [isCarouselInitialized, onAfterCarouselInitialization] =
    useCarouselAfterInitialization(setNavigationControllerState);

  const showNavigationControllers = useMemo(() => {
    if (
      isCarouselInitialized &&
      countOfElements > 0 &&
      (Number(sliderParams?.slidesPerView) ?? 0) < countOfElements &&
      hasNavigationControllers
    ) {
      return true;
    }

    return false;
  }, [
    isCarouselInitialized,
    countOfElements,
    hasNavigationControllers,
    sliderParams?.slidesPerView,
  ]);

  const handlePressPrev = useCallback(() => {
    // @ts-expect-error Свайпер точно будет
    const swiper = swiperRef.current?.swiper as SwiperCore;

    swiper.slidePrev();
  }, [swiperRef]);

  const handlePressNext = useCallback(() => {
    // @ts-expect-error Свайпер точно будет
    const swiper = swiperRef.current?.swiper as SwiperCore;
    swiper.slideNext();
  }, [swiperRef]);

  return (
    <div
      className={cn(styles.carouselWrapper, {
        [styles.carouselWrapperHidden]: !isCarouselInitialized,
      })}
    >
      <SwiperReact
        // @ts-expect-error ref exists
        ref={swiperRef}
        slidesPerView={slidesPerView}
        spaceBetween={spaceBetween}
        breakpoints={breakpoints}
        onInit={setCarouselLoopState}
        onSlideChange={setNavigationControllerState}
        onAfterInit={onAfterCarouselInitialization}
        onResize={setNavigationControllerState}
        loop={carouselLoop}
      >
        {data.map((slide: T, index: number) => (
          <SwiperSliderReact key={getKey?.(slide) ?? index}>
            {renderSlide(slide)}
          </SwiperSliderReact>
        ))}
      </SwiperReact>
      {showNavigationControllers && (
        <>
          <CarouselControl
            type="prev"
            onPressHandler={handlePressPrev}
            offsetYControl={offsetYControl}
            disabled={isPrevNavigationControllerDisabled}
          />
          <CarouselControl
            type="next"
            onPressHandler={handlePressNext}
            offsetYControl={offsetYControl}
            disabled={isNextNavigationControllerDisabled}
          />
        </>
      )}
    </div>
  );
};

export const Carousel = memo(Component) as typeof Component;
