'use client';
import cn from 'classnames';
import React, { useCallback, useState } from 'react';
import styles from './radio-button.module.scss';

export type TRadioButtonOption = {
  value: string;
  label: string;
};

export type TRadioButtonProps = {
  /**
   * Задает доступность кнопки.
   * По-умолчанию равен «false»
   */
  isDisabled?: boolean;

  /**
   * Выбранный элемент
   */
  selected?: string;

  /**
   * Выбранный элемент по умолчанию
   */
  defaultSelected?: string;

  /**
   * Варианты выбора
   */
  options: Array<TRadioButtonOption>;

  /**
   * Задает размер кнопки.
   * По-умолчанию равен «M»
   */
  size?: 'l' | 'm';

  /**
   * Кнопки разделены промежутками
   * По-умолчанию равен «false»
   */
  hasGap?: boolean;

  /**
   * Дополнительный класс
   */
  className?: string;

  /**
   * Обработчик выбора элемента
   */
  onChange?(value: string): void;
};

export const RadioButton = ({
  isDisabled = false,
  selected,
  defaultSelected,
  options,
  onChange,
  size = 'm',
  hasGap,
  className,
}: TRadioButtonProps): JSX.Element => {
  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    defaultSelected,
  );

  const value = selected || selectedOption;

  const handleClick = useCallback(
    (newValue: string) => {
      setSelectedOption(newValue);
      onChange?.(newValue);
    },
    [onChange],
  );

  return (
    <div
      className={cn(
        styles.radioButtonContainer,
        {
          [styles.radioButtonContainerWithGap]: hasGap,
        },
        className,
      )}
    >
      {options.map((option: TRadioButtonOption) => (
        <div
          key={option.value}
          className={cn(styles.radioButtonWrapper, {
            [styles.radioButtonDisabled]: isDisabled,
            [styles.radioButtonSelected]: value === option.value,
          })}
        >
          <button
            onClick={() => handleClick(option.value)}
            className={cn(styles.radioButton, {
              [styles[`size-${size}`]]: !!size,
            })}
            type="button"
            disabled={isDisabled}
          >
            {option.label}
          </button>
        </div>
      ))}
    </div>
  );
};
