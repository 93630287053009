import { TImageType } from './image.type';
import { MediaBreakpoint } from '../../../_shared';

/** Возвращает тип изображения путем получения его расширения */
export const getImageTypeFromProvidedSrc = (src: string): string => {
  if (typeof src === 'string' && src.length > 0) {
    return src.slice(src.lastIndexOf('.') + 1);
  }

  return '';
};

/** Возвращает url изображения без расширения */
export const getImageUrlWithoutFormat = (src: string): string => {
  if (typeof src === 'string' && src.length > 0) {
    return src.slice(0, src.lastIndexOf('.'));
  }

  return '';
};

type TImageFormat = { type: TImageType; media: MediaBreakpoint };

type TMakeImageMetaParams = {
  /** Основной источник изображения */
  src: string;
  /** Название изображения */
  alt: string;
  /** Форматы изображения */
  formats: Array<TImageFormat>;
};

export type TImageMetaParams = {
  /** Основной источник изображения */
  src: string;
  /** Название изображения */
  alt: string;
  /** SrcSets */
  srcSet: Array<{
    src: string;
    media: MediaBreakpoint;
  }>;
};

/** Позволяет сформировать image объект, удобный для работы с соурсетами */
export const makeImageMeta = ({
  src,
  alt,
  formats,
}: TMakeImageMetaParams): TImageMetaParams => {
  const srcSet = formats.map((format: TImageFormat) => ({
    src: `${getImageUrlWithoutFormat(src)}.${format.type}`,
    media: format.media,
  }));

  return {
    src,
    srcSet,
    alt,
  };
};
