import cn from 'classnames';
import React, { memo } from 'react';
import styles from './column.module.scss';

export const checkOffsetExists = (offset?: number): boolean => {
  return typeof offset === 'number';
};

type TColumnWidth = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type TSpan = {
  s: TColumnWidth | 'auto';
  m?: TColumnWidth | 'auto';
  l?: TColumnWidth | 'auto';
  xl?: TColumnWidth | 'auto';
};

type TOffset = {
  s?: TColumnWidth;
  m?: TColumnWidth;
  l?: TColumnWidth;
  xl?: TColumnWidth;
};

export type TColumnProps = {
  /**
   * Группа свойств, в которой указывается ширина колонки
   * в определенной контрольной точке
   */
  span: TSpan;
  /**
   * Группа свойств, в которой указывается отступ для колонки
   * в определенной контрольной точке
   */
  offset?: TOffset;
  /**
   *
   */
  children: React.ReactNode;
  /**
   * Пользовательский класс стилей
   */
  className?: string;
};

/**
 * @deprecated use grid.Column
 */
export const Column = memo(function Column(props: TColumnProps): JSX.Element {
  return (
    <div
      className={cn({
        [styles[`span-${props.span.s}`]]: !!props.span.s,
        [styles[`span-m-${props.span.m ?? ''}`]]: !!props.span.m,
        [styles[`span-l-${props.span.l ?? ''}`]]: !!props.span.l,
        [styles[`span-xl-${props.span.xl ?? ''}`]]: props.span.xl,
        [styles[`offset-${props.offset?.s ?? ''}`]]: checkOffsetExists(
          props.offset?.s,
        ),
        [styles[`offset-m-${props.offset?.m ?? ''}`]]: checkOffsetExists(
          props.offset?.m,
        ),
        [styles[`offset-l-${props.offset?.l ?? ''}`]]: checkOffsetExists(
          props.offset?.l,
        ),
        [styles[`offset-xl-${props.offset?.xl ?? ''}`]]: checkOffsetExists(
          props.offset?.xl,
        ),
        [String(props.className)]: !!props.className,
      })}
    >
      {props.children}
    </div>
  );
});
