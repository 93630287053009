import cn from 'classnames';
import React, { memo } from 'react';
import styles from './row.module.scss';

export type TRowProps = {
  /**
   *
   */
  children: React.ReactNode;
  /**
   * Пользовательский класс стилей
   */
  className?: string;
  /**
   * Разворачивает направление
   */
  reverse?: boolean;
};

/**
 * @deprecated use grid.Row
 */
export const Row = memo(function Row(props: TRowProps): JSX.Element {
  return (
    <div
      className={cn(styles.row, {
        [String(props.className)]: !!props.className,
        [styles.reverse]: props.reverse,
      })}
    >
      {props.children}
    </div>
  );
});
