import { RegionModel, geoService } from 'lib';
import { useState, useEffect } from 'react';

/**
 * Хук для получения регионов
 */
export const useRegions = (): Array<RegionModel> => {
  const [regions, setRegions] = useState<Array<RegionModel>>([]);

  useEffect(() => {
    void geoService.getRegions().then((responseRegions: Array<RegionModel>) => {
      setRegions(responseRegions || []);
    });
  }, []);

  return regions;
};
