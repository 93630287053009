'use client';
import { ReactNode, type JSX } from 'react';
import { DrawerBody } from './drawer-body.component';
import { DrawerHeader } from './drawer-header.component';
import { Drawer } from './drawer.component';
import styles from './drawer.module.scss';

type TTypicalDrawerProps = {
  isOpen: boolean;
  title: string;
  children: ReactNode;
  onClose(): void;
};

export function TypicalDrawer(props: TTypicalDrawerProps): JSX.Element {
  return (
    <Drawer right isOpen={props.isOpen} onClose={props.onClose}>
      <DrawerHeader onClose={props.onClose}>{props.title}</DrawerHeader>
      <DrawerBody className={styles.typicalBody}>{props.children}</DrawerBody>
    </Drawer>
  );
}
