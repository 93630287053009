import { isObject } from '@brand/utils';
import cn from 'classnames';
import React, { memo, useMemo } from 'react';
import styles from './spacer.module.scss';

type TMediaValue<T> = {
  s?: T;
  sm?: T;
  m?: T;
  l?: T;
  xl?: T;
};

export type TSpacerProps = {
  size: number | TMediaValue<number>;
};

export const Spacer = memo(({ size }: TSpacerProps): JSX.Element => {
  const sizes = isObject(size) ? size : { s: size };
  const sizeVars = useMemo(
    () =>
      Object.entries(sizes).reduce(
        (acc: Record<string, number>, [media, value]: [string, number]) => {
          acc[`--height-${media}`] = value;
          return acc;
        },
        {},
      ),
    [size],
  );

  return (
    <div
      style={sizeVars}
      className={cn(
        styles.spacer,
        Object.keys(sizes).map((media: string) => styles[`spacer-${media}`]),
      )}
    />
  );
});
Spacer.displayName = 'Spacer';
