'use client';
import { useSizeObserver, useIsomorphicLayoutEffect } from '@brand/utils';
import { Cross } from '@tochka-modules/t15-ui-kit-icons-ssr/18/Stroked/Cross';
import cx from 'classnames';
import { Container } from 'components/ui-kit';
import { useMedia } from 'hooks';
import { usePathname, useRouter } from 'next/navigation';
import React, { memo, useEffect, useLayoutEffect, useRef } from 'react';
import { useRibbonContext } from './providers';
import styles from './ribbon.module.scss';
import { Breakpoint } from '../../_shared';

const RIBBON_KEY_IN_STORAGE = 'shop-ribbon';

const setRibbonAlreadyShown = (ribbonId: string): void => {
  localStorage.setItem(RIBBON_KEY_IN_STORAGE, ribbonId);
};

type TRibbonProps = {
  background: {
    s: string;
    m?: string;
    l?: string;
  };
  /**
   * Когда появляется новая версия Ribbon, для нее нужно
   * сгененрировать новый id в формате uuid/v4, поместить
   * в переменную, а уже значение этой переменной передать
   * в переменную id. Это нужно для того, чтобы обновлять
   * состояние о том, закрывал пользователь уже текущий Ribbon или нет
   */
  id: string;
  className?: string;
};

/**
 * Ribbon -- это полностью кастомный компонент, который
 * на данный момент пересобирается под конкретные цели
 *
 * Когда появляется новая версия Ribbon, для нее нужно
 * сгененрировать новый id в формате uuid/v4, поместить
 * в переменную, а уже значение этой переменной передать
 * в переменную id. Это нужно для того, чтобы обновлять
 * состояние о том, закрывал пользователь уже текущий Ribbon или нет
 */
export const Ribbon = memo(function Ribbon({
  children,
  background,
  id,
  className,
}: React.PropsWithChildren<TRibbonProps>): JSX.Element | null {
  const { excludedPaths, isVisibleRibbon, setVisibleRibbon, setRibbonHeight } =
    useRibbonContext();
  const pathname = usePathname();
  const ref = useRef<HTMLDivElement | null>(null);
  const { height } = useSizeObserver(ref);

  const breakpoint = useMedia();
  const isDesktop = breakpoint > Breakpoint.MEDIUM_SCREEN;
  const isMobile = breakpoint <= Breakpoint.MEDIUM_SMALL_SCREEN;
  const isTablet = breakpoint <= Breakpoint.MEDIUM_SCREEN;

  const image =
    (isMobile && background.s) ||
    (isTablet && background.m) ||
    (isDesktop && (background.l || background.m)) ||
    background.s;

  const handleClose = (): void => {
    setVisibleRibbon(false);
    setRibbonAlreadyShown(id);
  };

  useEffect(() => {
    if (height) {
      setRibbonHeight(height);
    }
  }, [height, setRibbonHeight, isVisibleRibbon]);

  useLayoutEffect(() => {
    const isRibbonVisible =
      !excludedPaths.includes(pathname) &&
      localStorage.getItem(RIBBON_KEY_IN_STORAGE) !== id;

    setVisibleRibbon(isRibbonVisible);
  }, [excludedPaths, id, pathname, setVisibleRibbon]);

  if (!isVisibleRibbon) return null;

  return (
    <div
      ref={ref}
      className={cx(styles.ribbonRoot, className)}
      style={{
        backgroundImage: `url(${image})`,
      }}
    >
      <button
        type="button"
        onClick={handleClose}
        className={styles.closeButton}
      >
        <Cross />
      </button>

      <Container className={styles.fullHeightContainer}>{children}</Container>
    </div>
  );
});
