import { usePathname, useRouter } from 'next/navigation';

/**
 * Хук для перехода назад по истории внутри домена.
 * Работает в связке с компонентов NavigationHistory(modules/navigation/components/navigation-history-component)
 * @param fallbackUrl Роут для переключения, если истории нет
 */
export const useHistoryBack = (fallbackUrl: string = '/'): (() => void) => {
  const router = useRouter();
  const pathname = usePathname();

  const handleNavigateBack = (): void => {
    const previousUrl = document.querySelector<HTMLMetaElement>(
      '[name="__previous_route__"]',
    )?.content;

    /**
     * Проверяем что существует предыдущий путь и он не является дочерним текущего
     */
    if (previousUrl && !previousUrl.includes(pathname)) {
      void router.replace(previousUrl);
    } else {
      void router.replace(fallbackUrl);
    }
  };

  return handleNavigateBack;
};
