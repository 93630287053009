import cn from 'classnames';
import Link from 'next/link';
import React, { memo, ReactNode } from 'react';
import styles from './link.module.scss';

type TLinkProps = {
  /**
   * Адрес ссылки
   */
  href: string;
  /**
   * Задает внешний вид ссылки
   * По-умолчанию «none»
   */
  decoration?: 'underline' | 'none';
  /**
   * Дочерние компоненты
   */
  children: ReactNode;
};

export const InternalLink = memo(function InternalLink({
  decoration = 'none',
  ...props
}: TLinkProps) {
  return (
    <Link href={props.href}>
      <span
        className={cn({
          [styles.link]: 'none' === decoration,
          [styles.linkUnderline]: 'underline' === decoration,
        })}
      >
        {props.children}
      </span>
    </Link>
  );
});

export const ExternalLink = memo(function ExternalLink({
  decoration = 'none',
  ...props
}: TLinkProps) {
  return (
    <a
      href={props.href}
      rel="noopener noreferrer"
      className={cn({
        [styles.link]: 'none' === decoration,
        [styles.linkUnderline]: 'underline' === decoration,
      })}
    >
      {props.children}
    </a>
  );
});
