import { useCallback, useState } from 'react';
import type { Swiper, Swiper as SwiperCore } from 'swiper';

/** Позволяет определить можно или нет зацикливать слайды */
export const useCarouselLoopState = (
  loop: boolean,
  countOfTrueElements: number,
): readonly [boolean, (swiper: Swiper) => void] => {
  const [isCarouselLoop, setIsCarouselLoop] = useState(loop);

  const setCarouselLoopState = useCallback(
    (swiper: SwiperCore) => {
      const slidesPerView = swiper.params.slidesPerView;

      if (typeof slidesPerView !== 'number') {
        return;
      }

      if (countOfTrueElements <= slidesPerView) {
        setIsCarouselLoop(false);
      }
    },
    [countOfTrueElements],
  );

  return [isCarouselLoop, setCarouselLoopState] as const;
};
