import cn from 'classnames';
import React, { memo, ReactNode } from 'react';
import styles from './card.module.scss';

type TCardProps = {
  /**
   * Задает эффект при наведении мыши
   */
  hoverable?: boolean;
  /**
   * Дополнительный пользовательский класс
   */
  className?: string;
  /**
   * Дочерние компоненты
   */
  children: ReactNode;
  /**
   *
   */
  variant?: 'primary' | 'secondary';
  /**
   *
   */
  isStretch?: boolean;
  /**
   * Обработчик клика по компоненту
   */
  onClick?(): void;
};

export const Card = memo(function Card({
  hoverable = false,
  variant = 'primary',
  isStretch = false,
  ...props
}: TCardProps): JSX.Element {
  const componentProps: Partial<TCardProps> = {
    className: cn(styles.card, {
      [styles[`variant-${variant}`]]: !!variant,
      [styles.cardStretch]: isStretch,
      [styles.cardHoverable]: hoverable,
      [String(props.className)]: !!props.className,
    }),
  };

  if (props.onClick) {
    componentProps.onClick = props.onClick;
  }

  return <div {...componentProps}>{props.children}</div>;
});
