import Link from 'next/link';
import React, { memo, PropsWithChildren, useMemo } from 'react';
import styles from './clickable-card.module.scss';
import { Card } from '../card';
import { Typo } from '../typo';

type TClickableCardProps = {
  /**
   * Node, которая отображается в аватаре
   */
  avatarNode: JSX.Element | string;
  /**
   * Текст заголовка
   */
  header: string;
  /**
   * Основной текст
   */
  text: string;
  /**
   * Ссылка, на которую произойдет переход при клике на карточку
   */
  href?: string;
  /**
   * Обработчик события клика по карточке
   */
  onClick?(): void;
};

type TCardWrapperProps = PropsWithChildren<
  | { variant: 'empty' }
  | { variant: 'link'; href: string }
  | { variant: 'button'; onClick(): void }
>;

const CardWrapper = memo(function CardWrapper(props: TCardWrapperProps) {
  if ('link' === props.variant) {
    return (
      <Link className={styles.cardLinkWrapper} href={props.href}>
        {props.children}
      </Link>
    );
  }

  if ('button' === props.variant) {
    return (
      <div
        className={styles.cardButtonWrapper}
        role="button"
        onClick={props.onClick}
      >
        {props.children}
      </div>
    );
  }

  return <>{props.children}</>;
});

export const ClickableCard = memo(function ClickableCard(
  props: TClickableCardProps,
): JSX.Element {
  const wrapperProps = useMemo((): TCardWrapperProps => {
    if (props.href) return { variant: 'link', href: props.href };
    else if (props.onClick)
      return { variant: 'button', onClick: props.onClick };
    return { variant: 'empty' };
  }, [props.href, props.onClick]);

  return (
    <CardWrapper {...wrapperProps}>
      <Card className={styles.clickableCard} hoverable isStretch>
        <div className={styles.avatar}>
          <svg width="54" height="54" className={styles.avatarBackground}>
            <path d="M27 0c18.4 0 27 8.6 27 27s-8.6 27-27 27S0 45.4 0 27 8.6 0 27 0Z" />
          </svg>

          {typeof props.avatarNode === 'string' ? (
            <Typo className={styles.avatarText} variant="xl" weight="600">
              {props.avatarNode.toUpperCase()}
            </Typo>
          ) : (
            <div className={styles.avatarText}>{props.avatarNode}</div>
          )}
        </div>
        <Typo className={styles.header} variant="xl" weight="600">
          {props.header}
        </Typo>
        <Typo className={styles.text} variant="m">
          {props.text}
        </Typo>
      </Card>
    </CardWrapper>
  );
});
