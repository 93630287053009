import cn from 'classnames';
import React, { memo } from 'react';
import styles from './divider.module.scss';

type TDividerProps = {
  /**
   * Задает вариант отображения разделителя
   */
  variant: 'horizontal' | 'vertical';
};

export const Divider = memo(function Divider(
  props: TDividerProps,
): JSX.Element {
  return (
    <hr
      className={cn(styles.divider, {
        [styles.horizontalDivider]: 'horizontal' === props.variant,
        [styles.verticalDivider]: 'vertical' === props.variant,
      })}
    />
  );
});
