import { useRouter, usePathname } from 'next/navigation';

/**
 * Предоставляет обработчик, которые позволяет обновить
 * данные на стороне сервера. Необходим, например,
 * для того, чтобы после отправки данных формы запросить
 * обновленную колекцию элементов
 */
export const useServerSideValuesRefresh = (): {
  refresh: VoidFunction;
} => {
  const router = useRouter();
  const pathname = usePathname();

  return {
    refresh() {
      void router.replace(pathname);
    },
  };
};
