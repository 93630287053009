'use client';
import Cross from '@tochka-modules/t15-ui-kit-icons-ssr/18/Stroked/Cross';
import { PropsWithChildren, memo } from 'react';
import styles from './drawer.module.scss';
import { Typo } from '../typo';

type TDrawerHeaderProps = {
  onClose(): void;
} & PropsWithChildren;

export const DrawerHeader = memo(function DrawerHeader(
  props: TDrawerHeaderProps,
) {
  return (
    <header className={styles.drawerHeader}>
      <Typo align="center" variant="l" weight="500" className={styles.title}>
        {props.children}
      </Typo>
      <button
        className={styles.closeButton}
        type="button"
        onClick={props.onClose}
      >
        <Cross />
      </button>
    </header>
  );
});
