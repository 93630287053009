import React, { ForwardedRef, forwardRef, memo } from 'react';
import { TSrcSet } from './image.type';
import { getImageTypeFromProvidedSrc } from './image.utils';

export type TImageProps = {
  /** Запасной источник изображения */
  src: string;

  /** Название изображения */
  alt: string;

  /** Набор источников изображения */
  srcSet?: Array<TSrcSet>;

  /** Стилевой класс для изображения */
  className?: string;
};

/** https://nextjs.org/docs/messages/no-img-element
 *
 * @deprecated Используем next/image
 */
const Component = forwardRef<HTMLImageElement, TImageProps>(
  (
    { alt, src: fallbackSrc, srcSet = [], className }: TImageProps,
    ref: ForwardedRef<HTMLImageElement>,
  ): JSX.Element => {
    return (
      <picture>
        {srcSet.map((srcSetItem: TSrcSet) => {
          const srcType =
            srcSetItem.format ?? getImageTypeFromProvidedSrc(srcSetItem.src);

          return (
            <source
              key={srcSetItem.src}
              media={srcSetItem.media}
              srcSet={srcSetItem.src}
              type={`image/${srcType}`}
            />
          );
        })}
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img ref={ref} className={className} src={fallbackSrc} alt={alt} />
      </picture>
    );
  },
);

export const Image = memo(Component);

Image.displayName = 'Image';
