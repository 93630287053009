import cn from 'classnames';
import React, { memo, useMemo } from 'react';
import styles from './header.module.scss';

type TLevel = 1 | 2 | 3 | 4 | 5;

declare type TColors = 'black' | 'white' | 'primary';

export type THeaderProps = {
  /**
   * Задает уровень заголовка
   */
  level: TLevel;
  /**
   * Задает толщину начертания шрифта
   * По-умолчанию «400»
   */
  weight?: '400' | '600' | '700';
  /**
   * Задает горизонтальное выравнивание текста
   * По-умолчанию «left»
   */
  align?: 'left' | 'center' | 'right';
  /**
   * Идентификатор элемента
   */
  id?: string;
  /**
   *
   */
  children: React.ReactNode;
  /**
   * Задает цвет заголовка
   */
  color?: TColors | 'inherit';
  /**
   * Дополнительный пользовательский класс
   */
  className?: string;
};

export const Header = memo(function Header({
  align = 'left',
  weight = '400',
  color = 'black',
  ...props
}: THeaderProps): JSX.Element {
  const tag = `h${props.level}`;

  return React.createElement(
    tag,
    {
      ...(!!props.id && { id: props.id }),
      className: cn(styles.header, {
        [styles[`align-${align}`]]: !!align,
        [styles[`variant-h${props.level}`]]: !!props.level,
        [styles[`color-${color}`]]: !!color,
        [styles[`weight-${weight}`]]: !!weight,
        [String(props.className)]: !!props.className,
      }),
    },
    props.children,
  );
});
