'use client';

import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';

export const VOID_FUNCTION = (): void => void [];

type TProvidedValue = {
  excludedPaths: Array<string>;
  isVisibleRibbon: boolean;
  setVisibleRibbon: Dispatch<SetStateAction<boolean>>;
  setRibbonHeight: Dispatch<SetStateAction<number>>;
  ribbonHeight: number;
};

type TRibbonProviderProps = {
  excludedPaths: Array<string>;
} & PropsWithChildren;

const DEFAULT_RIBBON_HEIGHT = 140;
const RibbonContext = React.createContext<TProvidedValue>({
  ribbonHeight: DEFAULT_RIBBON_HEIGHT,
  isVisibleRibbon: false,
  setVisibleRibbon: VOID_FUNCTION,
  setRibbonHeight: VOID_FUNCTION,
  excludedPaths: [],
});

export const useRibbonContext = (): TProvidedValue => {
  return useContext(RibbonContext);
};

export const RibbonProvider = ({
  children,
  excludedPaths = [],
}: TRibbonProviderProps): JSX.Element => {
  const [isVisibleRibbon, setVisibleRibbon] = useState(false);
  const [ribbonHeight, setRibbonHeight] = useState(DEFAULT_RIBBON_HEIGHT);

  return (
    <RibbonContext.Provider
      value={{
        ribbonHeight,
        excludedPaths,
        isVisibleRibbon,
        setVisibleRibbon,
        setRibbonHeight,
      }}
    >
      {children}
    </RibbonContext.Provider>
  );
};
