'use client';

import { useScrollLock } from '@brand/utils';
import cn from 'classnames';
import { useRibbonContext } from 'components/ribbon';
import { ReactNode } from 'react';
import styles from './drawer.module.scss';

type TDrawerProps = {
  overlayHeader?: boolean;
  onClose: VoidFunction;
  isOpen?: boolean;
  right?: boolean;
  children?: ReactNode;
};

export function Drawer({
  children,
  overlayHeader,
  onClose,
  isOpen,
  right,
}: TDrawerProps): JSX.Element {
  const { isVisibleRibbon } = useRibbonContext();
  useScrollLock(!!isOpen);
  return (
    <>
      {/* затемненный оверлей на всю ширину экрана */}
      <div
        onClick={onClose}
        className={cn(styles.drawerOverlay, {
          [styles.drawerOverlayHeader]: overlayHeader,
          [styles.drawerIsOpen]: isOpen,
          [styles['drawerOverlay--ribbon']]: isVisibleRibbon,
        })}
      ></div>
      {/* контейнер куда рендерится весь контент drawer-а */}
      <div
        className={cn(styles.drawerContainer, {
          [styles.drawerRight]: right,
          [styles.drawerOverlayHeader]: overlayHeader,
          [styles.drawerIsOpen]: isOpen,
          [styles['drawerContainer--ribbon']]: isVisibleRibbon,
        })}
      >
        {children}
      </div>
    </>
  );
}
