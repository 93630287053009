import ArrowLeft from '@tochka-modules/t15-ui-kit-icons-ssr/24/Stroked/ArrowLeft';
import ArrowRight from '@tochka-modules/t15-ui-kit-icons-ssr/24/Stroked/ArrowRight';
import cn from 'classnames';
import React, { memo } from 'react';
import styles from './carousel-control.module.scss';

type TCarouselControlProps = {
  type: 'next' | 'prev';
  onPressHandler: () => void;
  disabled: boolean;
  offsetYControl?: number | string;
};

export const CarouselControl = memo(function CarouselControl({
  type,
  onPressHandler,
  disabled = false,
  offsetYControl = 0,
}: TCarouselControlProps): JSX.Element {
  const offsetTopOffsetStyle =
    typeof offsetYControl === 'number' ? `${offsetYControl}px` : offsetYControl;

  if (type === 'prev') {
    return (
      <button
        className={styles.button}
        style={{ top: offsetTopOffsetStyle }}
        onClick={onPressHandler}
        disabled={disabled}
      >
        <ArrowLeft />
      </button>
    );
  }

  return (
    <button
      className={cn(styles.button, styles.buttonRight)}
      style={{ top: offsetTopOffsetStyle }}
      onClick={onPressHandler}
      disabled={disabled}
    >
      <ArrowRight />
    </button>
  );
});
