'use client';
import { PasswordHide } from '@tochka-modules/t15-ui-kit-icons-ssr/24/Stroked/PasswordHide';
import { PasswordShow } from '@tochka-modules/t15-ui-kit-icons-ssr/24/Stroked/PasswordShow';
import React, { memo, useState } from 'react';
import styles from './input-password.module.scss';
import { Input, TInputProps } from '../input';

type TInputPasswordProps = Omit<TInputProps, 'type'>;

export const InputPassword = memo(function InputPassword(
  props: TInputPasswordProps,
) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <Input
      {...props}
      className={styles.inputPassword}
      type={isPasswordVisible ? 'text' : 'password'}
      accessor={
        <button
          type="button"
          className={styles.inputButton}
          onClick={() => setIsPasswordVisible((prev: boolean) => !prev)}
        >
          {isPasswordVisible ? <PasswordShow /> : <PasswordHide />}
        </button>
      }
    />
  );
});
