import { MoneyFormatter } from 'lib';
import React, { memo } from 'react';

export type TAmountProps = {
  /**
   * Сумма
   */
  value: number;
  /**
   * Валюта
   * По-умолчанию {@link MoneyFormatter.Currency.RUB}
   */
  currency?: MoneyFormatter.Currency;
  /**
   * Показывать или нет дробную часть?
   * По умолчанию показывается
   */
  withZeroFraction?: boolean;
};

export const Amount = memo(function Amount({
  value,
  currency = MoneyFormatter.Currency.RUB,
  withZeroFraction = true,
}: TAmountProps) {
  return (
    <span>{MoneyFormatter.format(value, { currency, withZeroFraction })}</span>
  );
});
