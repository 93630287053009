'use client';
import cn from 'classnames';
import React, { memo, ReactElement, useState } from 'react';
import styles from './text-area.module.scss';
import { Spacer } from '../spacer';
import { Typo } from '../typo';

export type TTextAreaProps = {
  /**
   * Подпись для поля ввода
   */
  label?: string;
  /**
   * Название поля
   */
  name?: string;
  /**
   * Значение
   */
  value: string;
  /**
   * Аксессуар, который отображается справа от поля ввода
   */
  accessor?: ReactElement;
  /**
   * Задает доступность кнопки.
   * По-умолчанию равен «false»
   */
  isDisabled?: boolean;
  /**
   *
   */
  isInvalid?: boolean;
  /**
   * Дополнительный пользовательский класс
   */
  className?: string;
  /**
   * Плейсхолдер
   */
  placeholder?: string;
  /**
   * Максимальная длина значения
   */
  maxLength?: number;
  /**
   * Размер text area
   */
  size?: number;
  /**
   * Обработчик на изменение значения поля ввода
   * @param value - новое значение
   */
  onChange(value: string): void;
};

// TODO: добавить вывод сообщения об ошибке, когда элемент невалиден
export const TextArea = memo(function Input({
  name = '',
  isDisabled = false,
  maxLength,
  isInvalid = false,
  size = 1,
  ...props
}: TTextAreaProps): JSX.Element {
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    const newValue = maxLength
      ? event.target.value.slice(0, maxLength)
      : event.target.value;
    props.onChange(newValue);
  };

  const inputProps = {
    name,
    className: styles.inputField,
    value: maxLength ? props.value.slice(0, maxLength) : props.value,
    placeholder: props.placeholder,
    onChange: handleChange,
    onFocus: () => setIsFocused(true),
    onBlur: () => setIsFocused(false),
    disabled: isDisabled,
    rows: size,
  };

  return (
    <>
      <div
        className={cn(styles.inputGroup, {
          [styles.inputGroupFocused]: isFocused,
          [styles.inputFieldWidthAccessor]: !!props.accessor,
          [styles.inputFieldInvalid]: !!isInvalid,
          [String(props.className)]: !!props.className,
          [styles.disabled]: isDisabled,
        })}
      >
        {props.label && (
          <>
            <label
              className={cn(styles.inputLabel, {
                [styles.inputLabelInvalid]: !!isInvalid,
                [styles.inputLabelFocused]:
                  isFocused || props.value.length !== 0,
              })}
            >
              {props.label}
            </label>
            <Spacer size={8} />
          </>
        )}
        <textarea {...inputProps} />

        {props.accessor !== undefined && (
          <div className={styles.inputAccessor}>{props.accessor}</div>
        )}
      </div>
      {maxLength && (
        <>
          <Spacer size={8} />
          <Typo align="right" color="gray400" variant="xm">
            {inputProps.value.length || 0} из {maxLength}
          </Typo>
        </>
      )}
    </>
  );
});
