import { useDebounce } from '@brand/utils';
import { geoService } from 'lib';
import { TCountry } from 'modules/cart';
import { useState, useEffect } from 'react';

/**
 * Хук для поиска города в регионе по поисковому запросу
 */
export const useSearchCountryByQuery = (): [
  Array<TCountry>,
  (value: string | undefined) => void,
] => {
  const [query, serQuery] = useState<string | undefined>('');
  const [countries, setCountries] = useState<Array<TCountry>>([]);

  const queryDebounced = useDebounce(query ?? '', 300);

  const handleInputChange = (value: string | undefined): void => {
    serQuery(value);
  };

  useEffect(() => {
    void geoService.searchCountryByName(queryDebounced).then(setCountries);
  }, [queryDebounced]);

  return [countries, handleInputChange];
};
