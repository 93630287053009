import { useCallback, useState } from 'react';
import type { Swiper as SwiperCore, SwiperOptions } from 'swiper';

export const useNavigationControllersState = (
  loop: boolean,
): readonly [
  boolean,
  boolean,
  (swiper: SwiperCore) => void,
  SwiperOptions | null,
] => {
  const [
    isPrevNavigationControllerDisabled,
    setIsPrevNavigationControllerDisabled,
  ] = useState(false);
  const [
    isNextNavigationControllerDisabled,
    setIsNextNavigationControllerDisabled,
  ] = useState(false);

  const [sliderParams, setSliderParams] = useState<SwiperOptions | null>(null);

  const setNavigationControllerState = useCallback(
    (swiper: SwiperCore) => {
      const slidesPerView = swiper.params.slidesPerView;
      setSliderParams(swiper.params);

      if (loop || typeof slidesPerView !== 'number') {
        return;
      }

      const countOfElements = swiper.slides.length;
      const slidesActiveIndex = swiper.activeIndex;

      // Нулевой индекс, значит левая кнопка заблокирована
      if (slidesActiveIndex === 0) {
        setIsPrevNavigationControllerDisabled(true);

        // Если количество элементов меньше, чем количество во вьюпорте
        if (countOfElements <= slidesPerView) {
          setIsNextNavigationControllerDisabled(true);
        } else {
          // Если нет, то разблокировать
          setIsNextNavigationControllerDisabled(false);
        }
      } else if (
        // Если дистанция активного слайда находится в финальной сете слайдов для просмотра
        Math.abs(slidesActiveIndex - countOfElements) <= slidesPerView
      ) {
        setIsPrevNavigationControllerDisabled(false);
        setIsNextNavigationControllerDisabled(true);
      } else {
        // Разблокировать кнопки
        setIsPrevNavigationControllerDisabled(false);
        setIsNextNavigationControllerDisabled(false);
      }
    },
    [loop],
  );

  return [
    isPrevNavigationControllerDisabled,
    isNextNavigationControllerDisabled,
    setNavigationControllerState,
    sliderParams,
  ] as const;
};
