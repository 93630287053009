'use client';
import cn from 'classnames';
import { ReactNode } from 'react';
import styles from './drawer.module.scss';

type TDrawerBodyProps = {
  children: ReactNode;
  className?: string;
};

export const DrawerBody = (props: TDrawerBodyProps): JSX.Element => {
  return (
    <aside className={cn(styles.drawerBody, props.className)}>
      {props.children}
    </aside>
  );
};
