'use client';
import { TOption } from '_shared';
import cn from 'classnames';
import React, { useMemo, useState } from 'react';
import ReactSelect, {
  GroupBase,
  InputProps,
  SingleValue,
  components,
} from 'react-select';
import styles from './select.module.scss';
import { Spacer } from '../spacer';

type TSelectProps = {
  options: Array<TOption>;
  placeholder?: string;
  name?: string;
  onChange?: (value: string | undefined) => void;
  onInputChange?: (value: string | undefined) => void;
  label?: string;
  isInvalid?: boolean;
  isOpen?: boolean;
  isDisabled?: boolean;
  noOptionsMessage?: string;
  initValue?: TOption;
  isAutocomplete?: boolean;
};

export const Select = ({
  options,
  placeholder = 'Выберите значение',
  name,
  onChange,
  label,
  onInputChange,
  isInvalid = false,
  isOpen,
  isDisabled,
  noOptionsMessage,
  initValue,
  isAutocomplete = true,
}: TSelectProps): JSX.Element => {
  const [selectedOption, setSelectedOption] = useState<null | TOption>(
    initValue ?? null,
  );

  const handleSelect = (option: SingleValue<TOption>): void => {
    onChange?.(option?.value);
    setSelectedOption(option);
  };

  const noOptionsMessageMapper = (): string =>
    noOptionsMessage || 'Ничего не найдено';

  const memoInput = useMemo(
    () => (props: InputProps<TOption, false, GroupBase<TOption>>) =>
      (
        <components.Input
          {...props}
          autoComplete={isAutocomplete ? undefined : 'new-password'}
        />
      ),
    [isAutocomplete],
  );

  return (
    <div
      className={cn(styles.inputGroup, {
        [styles.disabled]: isDisabled,
      })}
    >
      {label && (
        <>
          <label
            className={cn(styles.inputLabel, {
              [styles.inputLabelInvalid]: !!isInvalid,
            })}
          >
            {label}
          </label>
          <Spacer size={8} />
        </>
      )}
      <ReactSelect
        name={name}
        className={styles.select}
        classNamePrefix={styles.select}
        defaultValue={selectedOption}
        placeholder={placeholder}
        onInputChange={onInputChange}
        maxMenuHeight={200}
        onChange={handleSelect}
        options={options}
        menuIsOpen={isOpen}
        isDisabled={isDisabled}
        noOptionsMessage={noOptionsMessageMapper}
        value={selectedOption}
        components={{ Input: memoInput }}
      />
    </div>
  );
};
