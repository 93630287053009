import cn from 'classnames';
import React, { memo, ReactNode } from 'react';
import styles from './center.module.scss';

type TCenterProps = {
  variant?: 'horizontal' | 'vertical';
  children: ReactNode;
};

export const Center = memo(function Center({
  variant = 'horizontal',
  ...props
}: TCenterProps): JSX.Element {
  return (
    <div
      className={cn(styles.center, {
        [styles.centerVertically]: variant === 'vertical',
      })}
    >
      {props.children}
    </div>
  );
});
