import { useState } from 'react';
import type { Swiper as SwiperCore } from 'swiper';

/** Хук, возвращающий коллбек, который вызывается после инициализации карусели */
export const useCarouselAfterInitialization = (
  onCarouselAfterInitializationHandler: (carousel: SwiperCore) => void,
): readonly [boolean, (carousel: SwiperCore) => void] => {
  const [isCarouselInitialized, setIsCarouselInitialized] = useState(false);

  const onCarouselAfterInitialization = (carousel: SwiperCore): void => {
    setIsCarouselInitialized(true);

    onCarouselAfterInitializationHandler(carousel);
  };

  return [isCarouselInitialized, onCarouselAfterInitialization] as const;
};
