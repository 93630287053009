import { useDebounce } from '@brand/utils';
import { TOption } from '_shared';
import { geoService } from 'lib';
import { useState, useEffect } from 'react';

/**
 * Хук для поиска города в регионе по поисковому запросу
 */
export const useSearchCityByRegionAndQuery = (
  region: string,
  initValue?: TOption,
): [Array<string>, (value: string | undefined) => void] => {
  const [query, serQuery] = useState<string | undefined>(
    initValue?.value || 'all',
  );
  const [cities, setCities] = useState<Array<string>>([]);

  const queryDebounced = useDebounce(query ?? '', 300);

  const handleInputChange = (value: string | undefined): void => {
    serQuery(value);
  };

  useEffect(() => {
    if (region) {
      void geoService
        .searchCityByRegionName(region, queryDebounced || 'all')
        .then(setCities);
    }
  }, [queryDebounced, region]);

  return [cities, handleInputChange];
};
