'use client';
import cn from 'classnames';
import { FormikHandlers } from 'formik/dist/types';
import React, { memo, ReactElement, useState } from 'react';
import InputMask from 'react-input-mask';
import styles from './input.module.scss';
import { Spacer } from '../spacer';
import { Typo } from '../typo';

export type TInputProps = {
  /**
   * Подпись для поля ввода
   */
  label?: string;
  /**
   * HTML-тип.
   * По-умолчанию равен «text»
   */
  type?: 'text' | 'email' | 'password';
  /**
   * Название поля
   */
  name?: string;
  /**
   * Значение
   */
  value: string;
  /**
   * Аксессуар, который отображается справа от поля ввода
   */
  accessor?: ReactElement;
  /**
   * Задает доступность кнопки.
   * По-умолчанию равен «false»
   */
  isDisabled?: boolean;
  /**
   *
   */
  isInvalid?: boolean;
  /**
   *
   */
  invalidMessage?: string;
  /**
   * Дополнительный пользовательский класс
   */
  className?: string;
  /**
   * Плейсхолдер
   */
  placeholder?: string;
  /**
   * Маска
   */
  mask?: string | Array<string | RegExp>;
  /**
   * Максимальная длина значения
   */
  maxLength?: number;
  /**
   * Обработчик onBlur
   */
  onBlur?: FormikHandlers['handleBlur'];
  /**
   * Флаг: autocomplete
   */
  isAutocomplete?: boolean;

  /**
   * Атрибут для тестов
   */
  dataAt?: string;

  /**
   * Обработчик на изменение поля ввода
   * @param event - React.ChangeEvent<HTMLInputElement>
   */
  onSyntheticChange?(event: React.ChangeEvent<HTMLInputElement>): void;

  /**
   * Обработчик на изменение значения поля ввода
   * @param value - новое значение
   */
  onChange(value: string): void;
};

// eslint-disable-next-line complexity
export const Input = memo(function Input({
  type = 'text',
  name = '',
  isDisabled = false,
  mask,
  maxLength,
  isInvalid = false,
  invalidMessage,
  isAutocomplete,
  dataAt,
  ...props
}: TInputProps): JSX.Element {
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = maxLength
      ? event.target.value.slice(0, maxLength)
      : event.target.value;
    props.onChange(newValue);
    props.onSyntheticChange?.(event);
  };

  const inputProps = {
    name,
    className: styles.inputField,
    type,
    value: maxLength ? props.value.slice(0, maxLength) : props.value,
    placeholder: props.placeholder,
    ['data-at']: dataAt,
    onChange: handleChange,
    onFocus: () => setIsFocused(true),
    onBlur: (e: React.FocusEvent<unknown>) => {
      if (props?.onBlur) {
        props.onBlur(e);
      }

      setIsFocused(false);
    },
    disabled: isDisabled,
  };

  const autoComplete = isAutocomplete ? undefined : 'new-password';

  return (
    <>
      <div
        className={cn(styles.inputGroup, {
          [styles.inputGroupFocused]: isFocused,
          [styles.inputFieldWidthAccessor]: !!props.accessor,
          [styles.inputFieldInvalid]: !!isInvalid,
          [String(props.className)]: !!props.className,
          [styles.disabled]: isDisabled,
        })}
      >
        {props.label && (
          <>
            <label
              className={cn(styles.inputLabel, {
                [styles.inputLabelInvalid]: !!isInvalid,
                [styles.inputLabelFocused]:
                  isFocused || !!props.placeholder || props.value.length !== 0,
              })}
              htmlFor={name}
            >
              {props.label}
            </label>
            <Spacer size={8} />
          </>
        )}
        {mask ? (
          <InputMask
            {...inputProps}
            mask={mask}
            id={name}
            autoComplete={autoComplete}
          />
        ) : (
          <input {...inputProps} id={name} autoComplete={autoComplete} />
        )}

        {props.accessor !== undefined && (
          <div className={styles.inputAccessor}>{props.accessor}</div>
        )}
      </div>
      {isInvalid && invalidMessage && (
        <>
          <Spacer size={8} />
          <Typo color="alert" variant="xm">
            {invalidMessage}
          </Typo>
        </>
      )}
      {type === 'text' && maxLength && (
        <>
          <Spacer size={8} />
          <Typo align="right" color="gray400" variant="xm">
            {inputProps.value.length || 0} из {maxLength}
          </Typo>
        </>
      )}
    </>
  );
});
