import cn from 'classnames';
import Link from 'next/link';
import React, { memo, ReactNode, useMemo } from 'react';
import styles from './button.module.scss';
import { Spinner } from '../spinner';

type TButtonTypes = 'submit' | 'button' | 'inner-link' | 'outer-link' | 'span';
type TButtonVariants = 'primary' | 'secondary' | 'white' | 'inline-text';

export type TButtonProps = {
  /**
   * Адрес для ссылки
   */
  href?: string;
  /**
   * Задает тип кнопки. Может быть обычной кнопкой, кнопкой для отправки результатов
   * формы, а также внешней или внутренней ссылкой. Для внутренних ссылок используется
   * как обертка над next/link. По-умолчанию равен «button»
   */
  type?: TButtonTypes;
  /**
   * Задает доступность кнопки.
   * По-умолчанию равен «false»
   */
  isDisabled?: boolean;
  /**
   * Задает вариант внешнего вида кнопки
   */
  variant: TButtonVariants;
  /**
   * Задает размер кнопки.
   * По-умолчанию равен «M»
   */
  size?: 'xl' | 'l' | 'm';
  /**
   * Задает ширину кнопки относительно контейнера
   */
  isBlock?: boolean;
  /**
   * Позволяет настроить флоу открытия внешней ссылки
   */
  target?: '_blank' | '_self' | '_parent' | '_top';
  /**
   * Дочерние компоненты
   */
  children: string;
  /**
   *
   */
  isLoading?: boolean;

  dataAt?: string;
  className?: string;

  /**
   * Обработчик клика по кнопке
   */
  onClick?(event: React.MouseEvent<HTMLElement>): void;
};

const SPINNER_COLORS: Record<TButtonVariants, string> = {
  primary: styles.spinnerPrimaryVariant,
  secondary: styles.spinnerSecondaryVariant,
  white: styles.spinnerPrimaryVariant,
  ['inline-text']: styles.spinnerPrimaryVariant,
};

type TButtonWrapperProps = {
  isInnerLink: boolean;
  href?: string;
  children: ReactNode;
};

const ButtonWrapper = memo(function ButtonWrapper(
  props: TButtonWrapperProps,
): JSX.Element {
  if (props.isInnerLink && props.href !== undefined)
    return <Link href={props.href}>{props.children}</Link>;

  return <>{props.children}</>;
});

export const Button = memo(function Button({
  type = 'button',
  size = 'm',
  isDisabled = false,
  isBlock = false,
  isLoading = false,
  className,
  ...props
}: TButtonProps) {
  const tag = useMemo(() => {
    if (['outer-link'].includes(type)) return 'a';
    if (['span', 'inner-link'].includes(type)) return 'span';

    return 'button';
  }, [type]);

  const elementProps = useMemo(() => {
    if ('outer-link' === type)
      return {
        href: props.href,
        target: props.target,
        ['data-at']: props.dataAt,
      };

    if (['button', 'submit'].includes(type))
      return {
        onClick: props.onClick,
        type: type,
        disabled: isDisabled,
        ['data-at']: props.dataAt,
      };

    return {};
  }, [isDisabled, props.href, props.onClick, type, props.target, props.dataAt]);

  return (
    <ButtonWrapper isInnerLink={'inner-link' === type} href={props.href}>
      {React.createElement(
        tag,
        {
          ...elementProps,
          className: cn(styles.button, className, {
            [styles[`size-${size}`]]: !!size,
            [styles[`variant-${props.variant}`]]: !!props.variant,
            [styles[`variant-${props.variant}-disabled`]]:
              !!props.variant && isDisabled,
            [styles.buttonBlock]: isBlock,
          }),
        },
        <span
          className={styles.buttonInner}
          style={{
            width: `${props.children.length}ch`,
          }}
        >
          {isLoading ? (
            <Spinner color={SPINNER_COLORS[props.variant]} />
          ) : (
            props.children
          )}
        </span>,
      )}
    </ButtonWrapper>
  );
});
