import { Cross } from '@tochka-modules/t15-ui-kit-icons-ssr/24/Stroked/Cross';
import cn from 'classnames';
import React, { memo } from 'react';
import reactHotToast, {
  Toaster as ReactHotToaster,
  Toast as RToast,
} from 'react-hot-toast';
import styles from './toaster.module.scss';
import { Typo } from '../typo';

export const Toaster = memo(function Toaster() {
  return (
    <ReactHotToaster
      position="bottom-center"
      containerClassName={styles.toaster}
    />
  );
});

export enum TToastVariant {
  PRIMARY = 'primary',
  SUCCESS = 'success',
  ERROR = 'error',
}

type TToastProps = {
  message: string;
  variant: TToastVariant;
  onClose(): void;
};

export const Toast = memo(function Toast(props: TToastProps) {
  return (
    <div
      className={cn(styles.toast, {
        [styles[`variant-${props.variant}`]]: !!props.variant,
      })}
    >
      <Typo color="white">{props.message}</Typo>

      <button
        className={styles.closeButton}
        type="button"
        onClick={props.onClose}
      >
        <Cross />
      </button>
    </div>
  );
});

const DEFAULT_TOAST_DISPLAY_TIME = 2_000;

export const toast = {
  custom(message: string, { variant }: { variant: TToastVariant }): string {
    return reactHotToast.custom(
      (currentToast: RToast) => (
        <Toast
          message={message}
          variant={variant}
          onClose={() => reactHotToast.remove(currentToast.id)}
        />
      ),
      { duration: DEFAULT_TOAST_DISPLAY_TIME },
    );
  },
  success(message: string): string {
    return this.custom(message, { variant: TToastVariant.SUCCESS });
  },
  error(message: string): string {
    return this.custom(message, { variant: TToastVariant.ERROR });
  },
  dismiss(id?: string) {
    return reactHotToast.remove(id);
  },
};
